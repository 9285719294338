document.addEventListener("DOMContentLoaded", function () {
	const form = document.querySelector("#add-new-word");
	let isSubmitting = false;

	form.addEventListener("submit", function (event) {
		event.preventDefault();

		if (isSubmitting) {
			alert("Форма вже відправляється, зачекайте.");
			return;
		}

		isSubmitting = true;

		const formData = new FormData(form);
		formData.append("action", "handle_form_submission");
		formData.append("nonce", form.querySelector("#form_nonce").value);

		fetch(ajaxurl, {
			method: "POST",
			body: formData,
		})
			.then((response) => response.json())
			.then((data) => {
				isSubmitting = false;

				if (data.success) {
					alert(data.data);
					form.reset();
				} else {
					alert(data.data);
				}
			})
			.catch((error) => {
				isSubmitting = false;
				console.error("Error:", error);
			});
	});
});
